* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  background-color: #fcfcfc;
  padding: 0;
}

table {
  border-collapse: collapse;
}

table td:first-child,
table td:last-child {
  padding: 0 12px;
}

table td {
  padding: 7px 0;
  font-size: 24px;
  font-weight: bold;
}

table td span {
  font-weight: bold;
}

table tr:nth-child(odd) {
  background-color: #e7ebfc;
}

h1 {
  background-color: #1840e3; 
  color: #fcfcfc;          
  padding: 20px;            
  border-radius: 10px;      
  text-align: center;       
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
}
h2,
h3 {
  color: #1840e3;
}

fieldset {
  border: none;
}

legend {
  font-size: 38px;
  color: #1840e3;
}

.giant-number {
  font-size: 100px;
  font-weight: bold;
  color: #1333b6;
  text-align: center;
  > span {
    font-weight: bold;
  }
}

#root,
#root > div,
#root > div {
  height: 100%;
  width: 100%;
}

.flex-container {
  padding: 0 40px;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  display: flex;
  flex-wrap: wrap;
}

.flex-container > div {
  height: 33vh;
  width: 33vh;
  border-radius: 15px;
  margin: 16px;
  display: inline-block;
}

.animated-row-enter {
  background-color: #1333b6 !important;
  transition: background-color 1s ease-in-out;

  &.animated-row-enter-active {
    background-color: transparent !important;
  }
}

.animated-row-exit {
  display: none;
}

.leaflet-container {
  height: 100%;
  width: 100%;
}
